import { Link } from 'react-router-dom'

export const NavHeader = () => {
    return(
        <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="nav-bar w-nav">

        <div className="wrapper nav-bar-v1-wrapper">
        <a href="/" className="brand w-nav-brand" aria-label="home">
          <img src="assets/logo.png" alt="" className="logo" />
          <div className="logo-text">Contractogram</div>
          <div className="underline" style={{width: '0px'}} />
        </a>
        <div className="nav-bar-v1-navigation">
          <nav role="navigation" className="nav-menu-v1 w-nav-menu">
            <a href="/comingSoon" className="nav-link w-nav-link">FAQ</a>
            <div data-hover="true" data-delay={300} className="nav-dropdown w-dropdown">
              <div className="nav-link nav-link-dropdown w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                <div><span style={{color:'#7F7F7F'}}>INSTRUCTIONS</span></div>
                <img src="assets/5cea9c435abdf62b454f7dc1_mini-arrow-down-grey.svg" alt="" className="nav-link-arrow" />
              </div>
              <nav className="nav-dropdown-list w-dropdown-list" style={{opacity: 0}} id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0"><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Home v.1</a><a href="#" aria-current="page" className="nav-dropdown-link w-dropdown-link w--current" tabIndex={0}>Home v.2</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Home v.3</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Home v.4</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Home v.5</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Coming Soon</a></nav>
            </div>
            <div data-hover="true" data-delay={300} className="nav-dropdown w-dropdown">
              <div className="nav-link nav-link-dropdown w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                <div><span style={{color:'#7F7F7F'}}>PRICING</span></div>
                <img src="assets/5cea9c435abdf62b454f7dc1_mini-arrow-down-grey.svg" alt="" className="nav-link-arrow" />
              </div>
              <nav className="nav-dropdown-list w-dropdown-list" style={{opacity: 0}} id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1"><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Pricing v.1: Comparison</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Pricing v.2</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Pricing v.3</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Pricing v.4</a></nav>
            </div>
            <div data-hover="true" data-delay={300} className="nav-dropdown w-dropdown">
              <div className="nav-link nav-link-dropdown w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                <div><span style={{color:'#7F7F7F'}}>BLOG</span></div>
                <img src="assets/5cea9c435abdf62b454f7dc1_mini-arrow-down-grey.svg" alt="" className="nav-link-arrow" />
              </div>
              <nav className="nav-dropdown-list w-dropdown-list" style={{opacity: 0}} id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2"><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog v.1: List &amp; Sidebar</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog v.2: Grid &amp; Sidebar</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog v.3: Big Grid</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog v.4: Medium Grid</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog v.5: Small Grid</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Blog Post</a></nav>
            </div>
            <a href="/contact" className="nav-link w-nav-link">CONTACT</a>

            <a href="#" className="/contact"></a>
            <div data-hover="true" data-delay={300} className="nav-dropdown w-dropdown">
              <div className="nav-link nav-link-dropdown w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                <div><span style={{color:'#7F7F7F'}}>LOGIN</span></div>
                <img src="assets/5cea9c435abdf62b454f7dc1_mini-arrow-down-grey.svg" alt="" className="nav-link-arrow" />
              </div>
              <nav className="nav-dropdown-list w-dropdown-list" style={{opacity: 0}} id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4"><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Features</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Call to Action</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Testimonials</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Pricing Tables</a><a href="#" className="nav-dropdown-link w-dropdown-link" tabIndex={0}>Footers</a></nav>
            </div>
          </nav>
          <div className="menu-button w-clearfix w-nav-button" style={{WebkitUserSelect: 'text'}} aria-label="menu" role="button" tabIndex={0} aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
            <div className="menu-icon">
              <div className="menu-icon-line-top" />
              <div className="menu-icon-line-middle" />
              <div className="menu-icon-line-bottom" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
     </div>
    );
}