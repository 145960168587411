import { useState } from "react";
import axios from "axios";
import { PostLeadSubscribeSuccess } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeFailure } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeError } from "./LeadSubscribeResultDivs";
import validator from 'validator';

const SUBSCRIBE_LEAD_HTML_ELEMENT_ID = "#HOME_MIDDLE_A";
const SUBSCRIBE_BTN_TEXT_VAL = "Subscribe";

export const HomeMiddleSubscriptionForm = () => {
    const [email, setEmail] = useState("");
    const [showLeadSubscribeSuccess, setShowLeadSubscribeSuccess] = useState(false);
    const [showLeadSubscribeError, setShowLeadSubscribeError] = useState({ isDisplayed: false, errorMessage: "" });
    const [showLeadSubscribeFailure, setShowLeadSubscribeFailure] = useState(false);
    const [subscribeButtonText, setSubscribeButtonText] = useState(SUBSCRIBE_BTN_TEXT_VAL);

    const handleSubscription = event => {
        event.preventDefault();

        clearPostSubscribeResults();

        if (!validator.isEmail(email)) {
            setShowLeadSubscribeError({ isDisplayed: true, errorMessage: "Wrong email address!" });
            return;
        }

        setSubscribeButtonText("LOADING...");

        const lead = {
            email: email,
            isSubscribed: true,
            leadByHtmlElementId: window.location.href + SUBSCRIBE_LEAD_HTML_ELEMENT_ID
        }

        axios.post(process.env.REACT_APP_API_URL + "/subscribe", lead)
            .then(res => {
                if (res.status == '200') {
                    setShowLeadSubscribeSuccess(true);
                    resetForm();
                    resetState();
                }
            }).catch((err) => {
                setShowLeadSubscribeFailure(true);
                setSubscribeButtonText(SUBSCRIBE_BTN_TEXT_VAL);
            });
    };

    const clearPostSubscribeResults = () => {
        setShowLeadSubscribeSuccess(false);
        setShowLeadSubscribeFailure(false);
        setShowLeadSubscribeError(false);
    }

    const resetForm = () => {
        document.getElementById("wf-form-Footer-Subscribe").reset();
    }

    const resetState = () => {
        setEmail("");
        setShowLeadSubscribeFailure(false);
        setShowLeadSubscribeError(false);
        setSubscribeButtonText(SUBSCRIBE_BTN_TEXT_VAL);
    }

    return (
        <div className="subscribe-form-wrapper">
            <form id="wf-form-Footer-Subscribe" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" method="get" className="full-width w-form" onSubmit={e => handleSubscription(e)} style={{}}>
                <p><input type="email" onChange={e => setEmail(e.target.value)} className="input light no-margin w-input" maxLength="256" name="email-3" data-name="Email 3" placeholder="Your email address" id="email-3" required="" />
                </p>
                <a target="_blank" href="#" className="button color-4 w-inline-block" style={{ width: 200 }} onClick={
                    (e) => {
                        handleSubscription(e);
                    }
                }>
                    <div>{subscribeButtonText}</div>
                    <div className="button-arrow-space">
                        <img src="./assets/right-icon-white.svg" alt="" className="button-arrow-icon big" />
                    </div>
                </a>
                <div style={{ textAlign: 'left' }}>
                    {showLeadSubscribeSuccess ? <PostLeadSubscribeSuccess divStyle={{ display: 'inline-block', position: 'absolute', marginTop: 2, height: 30, lineHeight: 1, padding:0, paddingTop:10 }} message = "Thank you! Now the product is in active development, follow our news" /> : null}
                    {showLeadSubscribeFailure ? <PostLeadSubscribeFailure divStyle={{ display: 'inline-block', position: 'absolute', marginTop: 2, height: 30, lineHeight: 1, color: '#F6A8C4' }} /> : null}
                    {showLeadSubscribeError.isDisplayed ? <PostLeadSubscribeError subscribeErrorMessage={showLeadSubscribeError.errorMessage} divStyle={{ display: 'inline-block', position: 'absolute', marginTop: 2, height: 30, lineHeight: 1, color: '#F6A8C4'}} /> : null}
                </div>
            </form>
        </div>
    );
}