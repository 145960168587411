import { Footer } from "./FooterComponent";
import { NavHeader } from "./NavHeaderComponent";
import { HomeMainSubscriptionForm } from './HomeMainSubscriptionForm';
import { HomeMiddleSubscriptionForm } from './HomeMiddleSubscriptionForm'

const Home = () => {
  return (
    <div>
      <NavHeader />

      <div className="page-wrapper footer-v2-padding">

        <div id="Hero" data-w-id="d42f4a18-c548-94a9-aa46-dad9fd1393a8" className="section hero-v1-section">
          <div className="wrapper">
            <div className="side-blocks">
              <div className="side-block"><img src="./assets/main_demo.png" width={270} alt="" className="hero-iphone" /></div>
              <div className="side-block no-padding-side">
                <div className="side-info">
                  <div className="title white-main">Contractogram</div>
                  <h1>The only app every contractor needs <img src="./assets/4429886.png" width="32" height="32"/></h1>
                  <div className="divider white" />
                  <p className="paragraph-big">The smartest way to document your work and communicate with your crew. Save an hour a day, every day.</p>
                  <HomeMainSubscriptionForm />
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="section no-padding-bottom wf-section">
          <div className="wrapper">
            <div className="entry_intro">
              <h2>What Contractogram is built for?</h2>
              <div className="divider" />
              <p className="text-grey no-margin">Contractors are always on the go, sometimes hitting four or five locations per day — which makes communication even more crucial. Taking photos helps when writing bids, coordinating with crews and handling customer disputes but getting them organized created a huge headache.
              Contractogram encourages communication without phone calls and facilitate context without driving to the job sites. All processes are transparent</p>
            </div>
            <div className="features-v3 margin-bottom">
              <div data-w-id="64ebc55e-d461-537a-cdd1-1e01426b1b9a" className="feature-v3" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                <div className="feature-image-v1 _1">
                </div>
                <h5 style={{fontSize: '18px'}}><span className="text-color-4">01.</span> Take photos / videos</h5>
                <div className="divider margin-v2" />
                <p className="text-grey">Snap unlimited photos saved with who took it, where, and when. Add annotations and markups for even more&nbsp;context.</p>
              </div>
              <div data-w-id="64ebc55e-d461-537a-cdd1-1e01426b1ba9" className="feature-v3" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                <div className="feature-image-v1 _2">
                </div>
                <h5 style={{fontSize: '18px'}}><span className="text-color-4">02.</span> Store</h5>
                <div className="divider margin-v2" />
                <p className="text-grey">Organized, secure and unlimited storage. Contractogram gives you the confidence that every photo is organized and saved where you can find it — not locked away on someone’s phone.</p>
              </div>
              <div data-w-id="64ebc55e-d461-537a-cdd1-1e01426b1bb8" className="feature-v3" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                <div className="feature-image-v1 _3">
                </div>
                <h5 style={{fontSize: '18px'}}><span className="text-color-4">03.</span> Sync</h5>
                <div className="divider margin-v2" />
                <p className="text-grey">Sync with everyone on your team by accessing photos and conversations directly in context to a&nbsp;photo or&nbsp;project. Ditch messy group texts.</p>
              </div>
              <div data-w-id="64ebc55e-d461-537a-cdd1-1e01426b1bc7" className="feature-v3" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                <div className="feature-image-v1 _4">
                </div>
                <h5 style={{fontSize: '18px'}}><span className="text-color-4">04.</span> Share</h5>
                <div className="divider margin-v2" />
                <p className="text-grey">Share galleries, photo reports, marketing photos, and more with customers, insurance, and everyone in&nbsp;between. Make beautiful photo reports in seconds.</p>
              </div>
            </div>
          </div>
        </div>


        <div className="section">
          <div className="wrapper">
            <div className="center-features">
              <div className="center-features-column">
                <div data-w-id="2e8e8388-30ff-7906-3359-a29df92cf02e" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="center-feature">
                  <div className="icon ghost"><img src="./assets/location_sorted.png" alt="" /></div>
                  <h4>Automatically organized by location</h4>
                  <p className="text-grey">We group all photos taken by your workers at a job site into its own project.</p>
                </div>
                <div data-w-id="f88c4a8c-9333-5c12-8f2c-d1513213ead2" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="center-feature">
                  <div className="icon ghost"><img src="./assets/photo_notes.png" alt="" /></div>
                  <h4>Know who took it and when</h4>
                  <p className="text-grey">Each photo is timestamped along with the name of the user who took it.</p>
                </div>
                <div data-w-id="f88c4a8c-9333-5c12-8f2c-d1513213ead2" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="center-feature">
                  <div className="icon ghost"><img src="./assets/photo_tags.png" alt="" /></div>
                  <h4>Add tags to them</h4>
                  <p className="text-grey">Make photos easier to search for and filter through later on.</p>
                </div>
              </div>
              <div className="center-features-media"><img src="./assets/features.gif" width={670} alt="" /></div>
              <div className="center-features-column">
                <div data-w-id="29ceb073-d20a-17f6-8968-d10f41f1c5a7" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="center-feature">
                  <div className="icon ghost"><img src="./assets/photo_editing.png" alt="" /></div>
                  <h4>Draw on photos and add captions</h4>
                  <p className="text-grey">Easily highlight areas that need attention.</p>
                </div>
                <div data-w-id="3faf9eba-2e7d-de79-ed5e-82ec64a0f450" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="center-feature">
                  <div className="icon ghost"><img src="./assets/commenting.png" alt="" /></div>
                  <h4>Have inline conversations</h4>
                  <p className="text-grey">Comment, leave a voice note or tag people on any photo to start a conversation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section dark-mask no-padding-vertical wf-section">
          <div className="wrapper">
            <div className="message big">
              <div><h3 className="message-text">Subscribe for updates!</h3></div>
                <HomeMiddleSubscriptionForm />
            </div>
          </div>
          <div data-w-id="3d483393-5b72-3439-0f07-7800856164f7" className="bg-image _11" style={{ willChange: 'transform', transform: 'translate3d(0px, -25%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} />
        </div>

        <div className="section no-padding-bottom wf-section">
          <div className="wrapper">
            <div data-w-id="b2f6bafd-e250-226f-38a7-60a59f2dc290" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="intro wide">
              <div className="title">Contractogram</div>
              <h2><p className="intro-text-big no-margin">Your entire crew in sync. All of your projects on track.</p></h2>
              <div className="divider" />
              <p className="text-grey no-margin">With Contractogram, your company will be communicating more efficiently and working more productively on-site. Knowing exactly what’s going on at all your projects will allow you to call the shots from anywhere. Contractogram was built to save time and money.</p>
            </div>
            <div className="side-blocks mobile-reverse">
              <div className="side-block no-padding-left">
                <div className="side-info">
                  <div className="title">Contractogram</div>
                  <h2>Everyone’s job gets easier with Contractogram.</h2>
                  <div className="divider" />
                  <ul>
                    <li><p className="text-grey"><strong>Owners/​Presidents</strong> gain clarity into what’s happening day to day.</p></li>
                    <li><p className="text-grey"><strong>Office/​Admin</strong> spend less time hunting down photos from foremen and crew members.</p></li>
                    <li><p className="text-grey"><strong>Project Managers</strong> can stay on top of job site progress of as many projects as they’re juggling simultaneously.</p></li>
                    <li><p className="text-grey"><strong>Sales</strong> can capture all nitty gritty details so they can effectively bid work and hand off to the crews.</p></li>
                    <li><p className="text-grey"><strong>Crew Members</strong> can spend less time uploading photos at the end of each day.</p></li>
                  </ul>
                  <a href="/contact" className="link w-inline-block">
                    <div>Contact us</div>
                    <img src="./assets/right-mini-icon-dark.svg" alt="" className="arrow-icon" />
                  </a>
                </div>
              </div>
              <div className="side-block small-padding-side">
                <img src="./assets/crew_schema.png" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 97vw, 44vw" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', width: 500 }} data-w-id="b2f6bafd-e250-226f-38a7-60a59f2dc2a4" alt="" className="devices-image" /></div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="wrapper">
            <div className="side-blocks">
              <div className="side-block small-padding-side">
                <img src="./assets/setup-01.png" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, 43vw" alt="" />
              </div>
              <div className="side-block no-padding-right">
                <div className="side-info">
                  <div className="title">Contractogram</div>
                  <h2>Easiest setup</h2>
                  <p>Get everyone ready in minutes</p>
                  <div className="divider" />
                  <p className="text-grey"></p>
                  <a target="_blank" href="/contact" data-w-id="61930cd3-7f0e-ccd2-2155-6cf38db6d859" className="button color-4 w-inline-block">
                    <div>BEGIN YOUR WORK</div>
                    <div style={{ width: '0px', height: '20px' }} className="button-arrow-space"><img src="./assets/right-icon-white.svg" alt="" className="button-arrow-icon big" style={{ opacity: 0 }} /></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Pricing" className="section wf-section">
          <div className="wrapper">
            <div className="intro">
              <div className="title">Contractogram</div>
              <h2>Select your plan</h2>
              <div className="divider" />
              <p className="text-grey no-margin">Whether you work alone, have a growing business, or a large company across multiple locations, our rates are designed to grow with you.</p>
            </div>
            <div className="pricing-v1" >
              <div data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb473" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="pricing-v1-card">
                <div className="circle-icon color-5"><img src="./assets/startup.png" alt="" /></div>
                <h6 className="uppercase">Free</h6>
                <div className="pricing-v1-tag">$ 0</div>
                <div className="paragraph-small text-grey">free</div>
                <ul role="list" className="pricing-v1-list w-list-unstyled">
                  <li className="pricing-v1-list-item">
                    <div>1-3 Users</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>50 Projects</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Unlimited photos</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo tags</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo comments</div>
                  </li>
                </ul>
                <a target="_blank" href="/contact" data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb489" className="button ghost w-inline-block" style={{ width: 200 }}>
                  <div>START FOR FREE</div>
                  <div style={{ width: '0px' }} className="button-arrow-space"><img src="./assets/right-icon.svg" alt="" className="button-arrow-icon big" style={{ opacity: 0 }} /></div>
                </a>
              </div>
              <div data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb48e" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="pricing-v1-card place-top">
                <div className="circle-icon color-3"><img src="./assets/crown.png" alt="" /></div>
                <h6 className="uppercase">Pro</h6>
                <div className="pricing-v1-tag">$ 5.99</div>
                <div className="paragraph-small text-grey">monthly per 1 user</div>
                <ul role="list" className="pricing-v1-list w-list-unstyled">
                  <li className="pricing-v1-list-item">
                    <div>Up to 50 Users</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Unlimited Projects</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Unlimited photos</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo tags</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo comments</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo reports</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Before / After Photos</div>
                  </li>
                </ul>
                <a target="_blank" href="/contact" data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb4a4" className="button ghost w-inline-block">
                  <div>START</div>
                  <div style={{ width: '0px' }} className="button-arrow-space"><img src="./assets/right-icon.svg" alt="" className="button-arrow-icon big" style={{ opacity: 0 }} /></div>
                </a>
              </div>
              <div data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb4a9" style={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }} className="pricing-v1-card">
                <div className="circle-icon color-2"><img src="./assets/building.png" alt="" /></div>
                <h6 className="uppercase">ENTERPRISE</h6>
                <div className="pricing-v1-tag">Individual Price</div>
                <ul role="list" className="pricing-v1-list w-list-unstyled">
                  <li className="pricing-v1-list-item">
                    <div>50+ Users</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Unlimited Projects</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Unlimited Photos</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo tags</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo comments</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Photo reports</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Before / After Photos</div>
                  </li>
                  <li className="pricing-v1-list-item">
                    <div>Support</div>
                  </li>
                </ul>
                <a target="_blank" href="/contact" data-w-id="101b7779-8bf8-be8b-02b2-17f8799cb4bf" className="button ghost w-inline-block" style={{ width: 200 }}>
                  <div>CONTACT US</div>
                  <div style={{ width: '0px' }} className="button-arrow-space"><img src="./assets/right-icon.svg" alt="" className="button-arrow-icon big" style={{ opacity: 0 }} /></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="section haze wf-section">
          <div className="wrapper">
            <div className="side-blocks top">
              <div className="side-block no-padding">
                <div className="intro left">
                  <div className="title">Contractogram</div>
                  <h2 className="no-margin">News &amp; Updates</h2>
                  --in development--
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <a href="#" style={{ transform: 'translate3d(0px, 16px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: 0, transformStyle: 'preserve-3d' }} className="up-button w-inline-block w--current" />

    </div>
  );
}

export default Home;