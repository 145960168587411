import { useState } from "react";
import axios from "axios";
import { PostLeadSubscribeError } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeFailure } from "./LeadSubscribeResultDivs";
import { HomeMainLeadSubscribeAlreadySubscribed } from "./LeadSubscribeResultDivs";
import validator from "validator";

const ALREADY_SUBSCRIBED_EMAIL_LOCAL_STORAGE_KEY = "alreadySubscribedEmail";
const START_BTN_TEXT = "START";

export const HomeMainSubscriptionForm = () => {
    const [email, setEmail] = useState("");
    const [showSubscriptionFailure, setShowSubscriptionFailure] = useState(false);
    const [showSubscriptionError, setShowSubscriptionError] = useState({ isDisplayed: false, errorMessage: "" });
    const [showAlreadySubscribed, setShowAlreadySubscribed] = useState(localStorage.getItem(ALREADY_SUBSCRIBED_EMAIL_LOCAL_STORAGE_KEY) == null ? false : true);
    const [startButtonText, setStartButtonText] = useState(START_BTN_TEXT);

    const handleMainSubscriptionForm = event => {
        event.preventDefault();

        clearPostSubscribeResults();

        if (!validator.isEmail(email)) {
            setShowSubscriptionError({ isDisplayed: true, errorMessage: "Wrong Email address format!" });
            return;
        }

        setStartButtonText("LOADING...");

        const lead = {
            email: email,
            isSubscribed: true,
            leadByHtmlElementId: window.location.href + "#HOME_MAIN_A"
        }

        axios.post(process.env.REACT_APP_API_URL + "/subscribe", lead)
            .then(res => {
                if (res.status == '200') {
                    handleSuccessPostRequest(lead);
                }
            }).catch((err) => {
                setShowSubscriptionFailure(true);
                setStartButtonText(START_BTN_TEXT);
            });
    }

    const handleSuccessPostRequest = (lead) => {
        setShowAlreadySubscribed(true);
        setStartButtonText(START_BTN_TEXT);

        try {
            localStorage.setItem(ALREADY_SUBSCRIBED_EMAIL_LOCAL_STORAGE_KEY, lead.email);
        }

        catch (err) {

        }
    }

    const clearPostSubscribeResults = () => {
        setShowSubscriptionFailure(false);
        setShowSubscriptionError(false);
    }

    return (
        <>
            {(() => {
                if (showAlreadySubscribed) {
                    return (
                        <HomeMainLeadSubscribeAlreadySubscribed />
                    );
                } else {
                    return (
                        <>
                            <form id="wf-form-Footer-Subscribe" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" className="subscribe-form" onSubmit={e => handleMainSubscriptionForm(e)}>
                                <input type="email" onChange={e => setEmail(e.target.value)} className="input light no-margin w-input" maxLength="256" name="email-3" data-name="Email 3" placeholder="Your email address" id="email-3" required="" /*pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"*/ required />
                                <a target="_blank" href="#" className="button color-4 w-inline-block" style={{ marginLeft: 10, width: 200 }} onClick={
                                    e => {
                                        handleMainSubscriptionForm(e);
                                    }
                                }>
                                    <div>{startButtonText}</div>
                                    <div className="button-arrow-space">
                                        <img src="./assets/right-icon-white.svg" alt="" className="button-arrow-icon big" />
                                    </div>
                                </a>
                            </form>
                            
                            {showSubscriptionError.isDisplayed ? <PostLeadSubscribeError subscribeErrorMessage={showSubscriptionError.errorMessage} divStyle={{ marginTop: -10 }} /> : null}
                            {showSubscriptionFailure ? <PostLeadSubscribeFailure divStyle={{ marginTop: -10 }} /> : null}
                        </>
                    )
                }
            })()}
        </>
    )
}