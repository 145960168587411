import { useState } from "react";
import axios from "axios";
import { PostLeadSubscribeError } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeFailure } from "./LeadSubscribeResultDivs";
import { SendFeedbackMessageSuccess } from "./LeadSubscribeResultDivs";
import validator from 'validator';

const SEND_MESSAGE_HTML_ELEMENT_ID = "#CONTACT";

export const ContactSendMessageForm = () => {
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [showSendMessageSuccess, setShowSendMessageSuccess] = useState(false);
    const [showSendMessageError, setShowSendMessageError] = useState({ isDisplayed: false, errorMessage: "" });
    const [showSendMessageFailure, setShowSendMessageFailure] = useState(false);

    const handleSendMessage = event => {
        event.preventDefault();

        clearSendMessageResultResults();

        if (!validator.isEmail(email)) {
            setShowSendMessageError({ isDisplayed: true, errorMessage: "Wrong email address" });
            return;
        }

        if (phoneNumber != null && phoneNumber.length != 0 && !validator.isMobilePhone(phoneNumber)) {
            setShowSendMessageError({ isDisplayed: true, errorMessage: "Wrong phone number! Please, use 0501234567 or with country code" });
            return;
        }

        if (message.length > 3000) {
            setShowSendMessageError({ isDisplayed: true, errorMessage: "Your message is too long! Let's keep it's length up to 3k symbols" });
            return;
        }

        if (name.length > 100) {
            setShowSendMessageError({ isDisplayed: true, errorMessage: "Name is too long! Max. length is 100 symbols" });
            return;
        }

        const feedbackMessage = {
            userName: name,
            phoneNumber: phoneNumber,
            email: email,
            feedbackMessage: message
        }

        axios.post(process.env.REACT_APP_API_URL + "/feedbackMessage", feedbackMessage)
            .then(res => {
                if (res.status == '200') {
                    setShowSendMessageSuccess(true);
                    resetForm();
                    resetState();
                }
            }).catch((err) => {
                setShowSendMessageFailure(true);
            })
    }

    const clearSendMessageResultResults = () => {
        setShowSendMessageSuccess(false);
        setShowSendMessageFailure(false);
        setShowSendMessageError({ isDisplayed: false, errorMessage: "" });
    }

    const resetForm = () => {
        document.getElementById("email-form").reset();
    }

    const resetState = () => {
        setName("");;
        setPhoneNumber("");
        setEmail("");
        setMessage("");
        setShowSendMessageError({ isDisplayed: false, errorMessage: "" });
        setShowSendMessageFailure(false);
    }

    return (
        <div className="side-block-contact w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="side-block-contact-form">
                <div className="side-input-wrapper-50"><img src="./assets/name-icon.svg" alt="" className="input-icon-left" /><input type="text" className="input icon-left w-input" maxLength={256} name="Contact-Name-2" data-name="Contact Name 2" placeholder="Your name" id="Contact-Name-2" onChange={e => setName(e.target.value)}/></div>
                <div className="side-input-wrapper-50"><img src="./assets/phone-icon.png" alt="" className="input-icon-left" /><input type="tel" className="input icon-left w-input" maxLength={256} name="Contact-Name-2" data-name="Contact Name 2" placeholder="Phone number (Not required)" id="Contact-Name-2" pattern='[0-9]{10}' onChange={e => setPhoneNumber(e.target.value)}/></div>
                <div className="side-input-wrapper-50"><img src="./assets/email-icon.svg" alt="" className="input-icon-left" /><input type="email" className="input icon-left w-input" maxLength={256} name="Contact-Email-2" data-name="Contact Email 2" placeholder="Email address" id="Contact-Email-2" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Please, enter your email address here" required onChange={e => setEmail(e.target.value)}/></div>
                <div className="side-input-wrapper-100"><img src="./assets/message-icon.svg" alt="" className="text-area-icon" /><textarea id="Contact-Message-2" name="Contact-Message-2" placeholder="Please, type your message here..." maxLength={5000} required data-name="Contact Message 2" className="text-area icon-left w-input" defaultValue={""} onChange={e => setMessage(e.target.value)}/></div>

                <input type="submit" value="SEND" data-wait="Loading..." className="button color-1 w-button" style={{ marginLeft: 10 }} onClick={
                    (event) => {
                        handleSendMessage(event);
                    }
                }/>
            </form>

            { showSendMessageError.isDisplayed ? <PostLeadSubscribeError subscribeErrorMessage={showSendMessageError.errorMessage} divStyle={{ marginLeft: 15, display: 'inline-block', marginTop: 10, marginBottom: -50, bottom: 0 }} /> : null }
            { showSendMessageSuccess ? <SendFeedbackMessageSuccess divStyle={{ marginLeft: 15, display: 'inline-block', marginTop: 10, backgroundColor: 'rgb(87 237 161 / 10%)', marginBottom: -60, bottom: 0 }}/> : null }
            { showSendMessageFailure ? <PostLeadSubscribeFailure divStyle={{ marginLeft: 15, display: 'inline-block', marginTop: 10, marginBottom: -50, bottom: 0 }} /> : null }

        </div>
    );
}