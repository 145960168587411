import { useState } from "react";
import axios from "axios";
import { PostLeadSubscribeSuccess } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeFailure } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeError } from "./LeadSubscribeResultDivs";
import validator from 'validator';

const SEND_BUTTON_TEXT = "SEND";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [showPostLeadSubscribeSuccess, setShowPostLeadSubscribeSuccess] = useState(false);
  const [showPostLeadSubscribeError, setShowPostLeadSubscribeError] = useState({ isDisplayed: false, errorMessage: "" });
  const [showPostLeadSubscribeFailure, setShowPostLeadSubscribeFailure] = useState(false);
  const [sendButtonText, setSendsButtonText] = useState(SEND_BUTTON_TEXT);

  const handleEmailSubscription = event => {
    event.preventDefault();

    clearPostSubscribeResults();

    if (!validator.isEmail(email)) {
      setShowPostLeadSubscribeError({ isDisplayed: true, errorMessage: "Wrong email address! Please, check format" });
      return;
    }

    setSendsButtonText("LOADING...");

    const lead = {
      email: email,
      isSubscribed: true,
      leadByHtmlElementId: window.location.href + "#FOOTER_BTN"
    }

    axios.post(process.env.REACT_APP_API_URL + "/subscribe", lead)
      .then(res => {
        if (res.status == '200') {
          setShowPostLeadSubscribeSuccess(true);
          resetForm();
          resetState();
        }
      }).catch((err) => {
        setShowPostLeadSubscribeFailure(true);
        setSendsButtonText(SEND_BUTTON_TEXT);
      })
  }

  const clearPostSubscribeResults = () => {
    setShowPostLeadSubscribeSuccess(false);
    setShowPostLeadSubscribeFailure(false);
    setShowPostLeadSubscribeError({ isDisplayed: false, errorMessage: "" });
  }

  const resetForm = () => {
    document.getElementById("wf-form-Footer-Subscribe-footer").reset();
  }

  const resetState = () => {
    setEmail("");
    setShowPostLeadSubscribeFailure(false);
    setShowPostLeadSubscribeError(false);
    setSendsButtonText(SEND_BUTTON_TEXT);
  }

  return (
    <div className="footer-v1-section">
      <div className="wrapper text-white">
        <div className="footer-v1">
          <div className="footer-v1-panel">
            <div className="footer-v1-nav">
              <a href="#top" className="footer-v1-nav-link">Up ↑</a>
              <a href="/#Pricing" className="footer-v1-nav-link">Pricing</a>
              <a href="/comingSoon" className="footer-v1-nav-link">FAQ</a>
              <a href="/comingSoon" className="footer-v1-nav-link">INSTRUCTIONS</a>
              <a href="/contact" className="footer-v1-nav-link">CONTACT</a></div>
          </div>
          <div className="footer-v1-contact-wrapper">
            <div className="subscribe-form-wrapper">
              <h3>Questions left?</h3>
              <p>Please, leave your email address and we'll reach out to you</p>
              <div className="full-width w-form">
                <form id="wf-form-Footer-Subscribe-footer" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" className="subscribe-form" posturl="/feedbackMessage">
                  <input type="email" onChange={e => setEmail(e.target.value)} className="input dark2 no-margin w-input" maxLength={256} name="email-3" data-name="email" placeholder="Email address" id="email-3" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Enter your email address" required />
                  <input type="submit" value={sendButtonText} data-wait="LOADING..." className="button subscribe-icon-button-footer w-button" style={{ width: 200, marginLeft: 4 }} onClick={e => {
                    handleEmailSubscription(e);
                  }
                  } />
                </form>

                {showPostLeadSubscribeSuccess ? <PostLeadSubscribeSuccess /> : null}
                {showPostLeadSubscribeFailure ? <PostLeadSubscribeFailure /> : null}
                {showPostLeadSubscribeError.isDisplayed ? <PostLeadSubscribeError subscribeErrorMessage={showPostLeadSubscribeError.errorMessage} /> : null }
              </div>
              <div className="social-icons">
                <a href="https://t.me/padpalatki/" target="_blank" className="social-link w-inline-block"><img src="./assets/telegram.png" alt="" /></a>
                {/*<a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b50881e8ac393ff528e54f0_facebook-icon-white.svg" alt="" /></a>
                  <a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b5088210f1c7e8c2ae4cd10_instagram-icon-white.svg" alt="" /></a>
                  <a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b508821953aa0b7c491657b_pinterest-icon-white.svg" alt="" /></a>
                  <a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b50880dd3842e31896a6193_behance-icon-white.svg" alt="" /></a>
                  <a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b50881d8723ee4866671044_dribbble-icon-white.svg" alt="" /></a>
    <a href="#" target="_blank" className="social-link w-inline-block"><img src="https://assets.website-files.com/5b4ee9e81559e9206bd17e7c/5b5088243057364b6b762d62_youtube-icon-white.svg" alt="" /></a>*/}
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-left">
              <div className="text-white-faded">Made with ❤️ by <a href="#" target="_blank" className="link-white">PADPALATKI ©</a></div>
            </div>
            <div className="footer-bottom-right">
              <a href="#" className="link-white">Licensing</a>
              <div className="footer-bottom-divider" />
              <a href="#" className="link-white">Changelog</a>
            </div>
            <a href="#" className="footer-v1-top-button w-inline-block"><img src="./assets/fadbe_up-icon.svg" alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  );
}