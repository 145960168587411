import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './HomeComponent';
import { Contact } from './ContactComponent';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { ComingSoon } from './ComingSoonComponent';


ReactDOM.render(
  <React.StrictMode>
     <BrowserRouter>
     
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/comingSoon" element={<ComingSoon/>} />
    </Routes>

  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


