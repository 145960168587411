import { NavHeader } from "./NavHeaderComponent";
import { useState } from "react";
import axios from "axios";
import { PostLeadSubscribeSuccess } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeFailure } from "./LeadSubscribeResultDivs";
import { PostLeadSubscribeError } from "./LeadSubscribeResultDivs";
import validator from 'validator';

const SUBSCRIBE_LEAD_HTML_ELEMENT_ID = "#COMING_SOON_A";
const SEND_BUTTON_TEXT = "SEND";

export const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [showPostLeadSubscribeSuccess, setShowPostLeadSubscribeSuccess] = useState(false);
  const [showPostLeadSubscribeError, setShowPostLeadSubscribeError] = useState({ isDisplayed: false, errorMessage: "" });
  const [showPostLeadSubscribeFailure, setShowPostLeadSubscribeFailure] = useState(false);
  const [sendButtonText, setSendsButtonText] = useState(SEND_BUTTON_TEXT);

  const handleEmailSubscription = event => {
    event.preventDefault();

    clearPostSubscribeResults();

    if (!validator.isEmail(email)) {
      setShowPostLeadSubscribeError({ isDisplayed: true, errorMessage: "Wrong email address! Check format" });
      return;
    }

    setSendsButtonText("LOADING...");

    const lead = {
      email: email,
      isSubscribed: true,
      leadByHtmlElementId: window.location.href + SUBSCRIBE_LEAD_HTML_ELEMENT_ID
    }

    axios.post(process.env.REACT_APP_API_URL + "/subscribe", lead)
      .then(res => {
        if (res.status == '200') {
          setShowPostLeadSubscribeSuccess(true);
          resetForm();
          resetState();
        }
      }).catch((err) => {
        setShowPostLeadSubscribeFailure(true);
        setSendsButtonText(SEND_BUTTON_TEXT);
      })
  }

  const clearPostSubscribeResults = () => {
    setShowPostLeadSubscribeSuccess(false);
    setShowPostLeadSubscribeFailure(false);
    setShowPostLeadSubscribeError({ isDisplayed: false, errorMessage: "" });
  }

  const resetForm = () => {
    document.getElementById("wf-form-Footer-Subscribe-footer").reset();
  }

  const resetState = () => {
    setEmail("");
    setShowPostLeadSubscribeFailure(false);
    setShowPostLeadSubscribeError(false);
    setSendsButtonText(SEND_BUTTON_TEXT);
  }

    return (
        <div>
        <NavHeader/>
        <div className="coming-soon">
        <div className="coming-soon-card">
          <div className="subscribe-form-wrapper">
            <h3>This page is being developed and will be available soon...</h3>
            <p>Subscribe to receive updates</p>
            <div className="full-width w-form">
            <form id="wf-form-Footer-Subscribe-footer" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" className="subscribe-form" posturl="/feedbackMessage">
                  <input type="email" onChange={ e => setEmail(e.target.value) } className="input dark2 no-margin w-input" maxLength={256} name="email-3" data-name="email" placeholder="Email address" id="email-3" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Please, enter your email address here" required />
                  <input type="submit" value={sendButtonText} data-wait="Loading..." className="button subscribe-icon-button-footer w-button" style={{ width: 200, marginLeft: 3 }} onClick={e => {
                    handleEmailSubscription(e);
                  }
                  } />
            </form>
                {showPostLeadSubscribeSuccess ? <PostLeadSubscribeSuccess /> : null}
                {showPostLeadSubscribeFailure ? <PostLeadSubscribeFailure /> : null}
                {showPostLeadSubscribeError.isDisplayed ? <PostLeadSubscribeError subscribeErrorMessage={showPostLeadSubscribeError.errorMessage} /> : null }
            </div>
            <div className="social-icons"><a href="https://t.me/padpalatki/" target="_blank" className="social-link w-inline-block"><img src="./assets/telegram.png" alt="Telegram" /></a>
            </div>
          </div>
        </div>
      </div></div>
    );
}