import { Footer } from './FooterComponent';
import { NavHeader } from './NavHeaderComponent';
import { ContactSendMessageForm } from './ContactSendMessageForm'

export const Contact = () => {
    return(
        <div>
    <NavHeader/>
        <div className="page-wrapper">
          <div className="section dark-mask nav-bar-v1-padding">
            <div className="wrapper">
              <div className="intro wide no-margin">
                <div className="title big white">Contractogram</div>
                <h1>Contact us</h1>
                <div className="divider white" />
                <p className="no-margin">Send us your message by filling out the form below or send a message to us in <a href="https://t.me/padpalatki" className='text-link' style={{color:"#ff9500"}}>Telegram</a>!</p>
              </div>
            </div>
            <div data-w-id="daa3c4e1-2ba9-96f3-7f86-ea29e75c0036" className="bg-image _13" style={{willChange: 'transform', transform: 'translate3d(0px, 4.8635%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} />
          </div>
          <div id="FAQ-Top" className="section border-bottom no-padding-vertical">
            <div className="wrapper">
              <div className="breadcrumbs">
                <a href="/" className="link-grey">HOME</a><img src="./assets/right-icon.svg" alt="" className="breadcrumbs-arrow" />
                <div className="text-light-grey">Contacts</div>
              </div>
            </div>
          </div>
          <div className="section haze">
            <div className="wrapper">
            <div className="side-blocks top">
<div className="side-block-short">
   <div className="side-info">
      <div className="title">Contractogram</div>
      <h2>Contact form 📭</h2>
      </div>
   </div>
      <ContactSendMessageForm/>
</div>
            </div>
          </div>
          <div className="section haze no-padding-top">
            <div className="wrapper side-paddings">
              <div className="info-card white">
                <img src="./assets/macbook-screen.png" alt="" className="info-card-image" />
                <div className="info-card-content">
                  <div className="title">Contractogram</div>
                  <h4>The smart way to document your work and communicate with your team.</h4>
                  <p className="paragraph-small text-grey">Contractogram will take care of all the routine work, saving you time, money and energy for work.</p>
                  <a href="/" className="link w-inline-block">
                    <div>Home</div>
                    <img src="./assets/right-mini-icon-dark.svg" alt="" className="arrow-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        
        <Footer/>
        </div>
        {/*[if lte IE 9]><![endif]*/}
      </div>
    );
};