export const PostLeadSubscribeSuccess = (props) => (
  <div className="form-success w-form-done" style={ props.divStyle }>
    <div>{props.message == null? "Thank you! Currently developing is in progress, we will be notifying you of news and updates." : props.message }</div>
  </div>
)

export const PostLeadSubscribeError = (props) => (
  <div className="form-error w-form-fail" style={ props.divStyle }>
    <div>Error: {props.subscribeErrorMessage}</div>
  </div>
)

export const PostLeadSubscribeFailure = (props) => (
  <div className="form-error w-form-fail" style={ props.divStyle }>
    <div>Unable to handle your request. Please try again a bit later</div>
  </div>
)

export const HomeMainLeadSubscribeAlreadySubscribed = () => (
  <div>
    <p className="paragraph-small" style={{ color: '#341E47' }}>Thank you! You subscribed now and we will be sending news and updates for you. You can also <a href="/contact" className="text-link">talk to us!</a></p>
  </div>
)

export const SendFeedbackMessageSuccess = (props) => (
  <div className="form-success w-form-done" style={ props.divStyle }>
    <div>Your message has been sent, thank you! You can reach out to us in <a href="https://t.me/padpalatki" className='text-link'>Telegram</a> and our new channels of communication are comming. Let's be in touch!</div>
  </div>
)